@import './variables/couleurs.scss';
@import './variables/layout.scss';

#Main
{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;

    .Vagues
    {
        height: 200px;
    }

    .transition
    {
        width: 100%;
        min-height: 175px;
        height: 200px;
        max-height: 250px;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}