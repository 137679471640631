@import '../variables/couleurs.scss';
@import '../variables/layout.scss';

.BoutonScrollPresentation
{
    width: 50px;
    height: 50px;
    color: $couleur__font__secondaire;
    border: 2.5px solid $couleur__background-color__blanc;
    border-radius: $taille__border-radius__bouton-scroll;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;

    animation: rebond 1s ease-in-out infinite alternate;
}

@keyframes rebond
{
    0%
    {
        translate: 0 5px;
    }

    100%
    {
        translate: 0 -5px;
    }
}