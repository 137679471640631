@import '../variables/layout.scss';
@import '../variables/couleurs.scss';

.Vagues
{
    width: 100%;
    height: 100%;
    position: relative;

    .Vague
    {
        width: 100%;
        height: 100%;
        position: absolute;
        background-size: 500px 300px;
    }

    .Vague:nth-of-type(1)
    {
        animation: animationVague1 30s linear infinite;
        z-index: 4;
        opacity: 1;
        animation-delay: 0s;
        bottom: 0px;
    }

    .Vague:nth-of-type(2)
    {
        animation: animationVague2 15s linear infinite;
        z-index: 3;
        opacity: 0.75;
        animation-delay: -5s;
        bottom: 20px;
    }

    .Vague:nth-of-type(3)
    {
        animation: animationVague3 30s linear infinite;
        z-index: 2;
        opacity: 0.5;
        animation-delay: -2s;
        bottom: 40px;
    }

    .Vague:nth-of-type(4)
    {
        animation: animationVague4 5s linear infinite;
        z-index: 1;
        opacity: 0.25;
        animation-delay: 0s;
        bottom: 60px;
    }

    @keyframes animationVague1
    {
        from
        {
            background-position-x: 0px;
        }

        to
        {
            background-position-x: 1000px;
        }
    }

    @keyframes animationVague2
    {
        from
        {
            background-position-x: 0px;
        }

        to
        {
            background-position-x: 1000px;
        }
    }

    @keyframes animationVague3
    {
        from
        {
            background-position-x: 0px;
        }

        to
        {
            background-position-x: 1000px;
        }
    }

    @keyframes animationVague4
    {
        from
        {
            background-position-x: 0px;
        }

        to
        {
            background-position-x: 1000px;
        }
    }
}