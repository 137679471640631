@import './variables/couleurs.scss';
@import './variables/layout.scss';
@import './variables/polices.scss';

#Footer
{
    width: 100%;
    background-color: $couleur__background-color__sixte;

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .contenu
    {
        display: flex;
        flex-direction: column;
        align-items: center;

        .titre-section
        {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: $typo__font-size__titre-section;
            width: min-content;
            border-bottom: 1px solid $couleur__background-color__primaire;
            margin-top: 10px;
            margin-bottom: 50px;
            width: auto;
            color: $couleur__font__secondaire;
        }

        .contenant-texte-console
        {
            color: $couleur__font__tertiaire;
            font-size: 1rem;
            max-width: 100vw;
            text-align: center;
        }

        .contenant-liens-sociaux
        {
            list-style-type: none;
            gap: 5px;
            padding: 5px;
            display: grid;            
            grid-template-rows: repeat(4, 1fr);
            grid-template-columns: 1fr;

            @media screen and (min-width: calc($layout-tablette))
            {
                grid-template-rows: repeat(2, 1fr);
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (min-width: calc($layout-gigaScreen / 1.1))
            {
                grid-template-rows: 1fr;
                grid-template-columns: repeat(4, 1fr);
            }
        }

        .copyright
        {
            margin-top: 10px;
            margin-bottom: 50px;

            &-attribution
            {
                color: $couleur__font__tertiaire;
                font-size: $typo__font-size__footer-copyright-attribution;
            }

            &-juridiction
            {
                color: $couleur__font__tertiaire;
                font-size: $typo__font-size__footer-copyright-juridiction;
            }
        }

        @media screen and (max-width: $layout-gigaScreen)
        {
            min-width: 75%;
        }

        @media screen and (min-width: $layout-gigaScreen)
        {
            max-width: 75%;
        }
    }

    @media screen and (min-width: $layout-gigaScreen)
    {
        min-height: 20vh;
    }
}