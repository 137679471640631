@import '../variables/couleurs.scss';
@import '../variables/layout.scss';
@import '../variables/polices.scss';

.Projet
{
    width: $taille__width__carte-projet;
    max-width: 90vw;
    background-color: $couleur__background-color__blanc;
    border-radius: $taille__border-radius__carte-projet;
    box-shadow: $taille__box-shadow__carte-projet $couleur__background-color__quinaire;
    transition: transform 300ms ease;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    cursor: pointer;
    
    a
    {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        border-radius: $taille__border-radius__carte-projet;

        .image-projet
        {
            height: 275px;
            background-color: $couleur__background-color__primaire;
            border-radius: $taille__border-radius__image-projet;
            color: $couleur__font__tertiaire;
            object-fit: cover;
        }

        .description-projet
        {
            display: flex;
            flex-direction: column;
            text-align: center;
            min-height: 10rem;

            &-titre
            {
                flex: 7;
                width: 100%;
                color: $couleur__font__quaternaire;
                font-size: $typo__font-size__projet-titre;
                font-family: $typo__font-family__secondaire;
                word-wrap: break-word;
                word-break: break-word;
                padding: 0px 5px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                font-weight: 600;
            }

            &-texte
            {
                flex: 10;
                color: $couleur__font__tertiaire;
                font-size: $typo__font-size__projet-paragraphe;
                word-wrap: break-word;
                word-break: break-word;
                padding: 0px 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 100;
            }
        }
    }

    &:hover
    {
        transform: scale(1.05);
    }

    @media screen and (max-width: $layout-mobile)
    {
        width: 80%;
    }
}