@import './variables/couleurs.scss';
@import './variables/layout.scss';
@import './variables/polices.scss';

.Appli
{
    background-color: $couleur__background-color__primaire;
    font-family: $typo__font-family__primaire;
    color: $couleur__font__primaire;
    font-weight: normal;
    overflow-x: hidden;
}