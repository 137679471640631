@import '../variables/couleurs.scss';
@import '../variables/layout.scss';
@import '../variables/polices.scss';

#Presentation
{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $couleur__font__secondaire;
    background-color: $couleur__background-color__tertiaire;
    background: linear-gradient($couleur__background-color__tertiaire,
                                $couleur__background-color__quaternaire,
                                $couleur__background-color__quaternaire,
                                $couleur__background-color__tertiaire);

    .contenu
    {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .titre-section
        {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: $typo__font-size__titre-section;
            width: min-content;
            border-bottom: 1px solid $couleur__background-color__blanc;
            margin-top: 10px;
        }

        &-grille
        {
            margin-top: 25px;
            padding: 5px;
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .contenant-image-profil
            {
                display: flex;
                justify-content: center;

                .image-profil
                {
                    width: $taille__width__photo-profil;
                    height: $taille__height__photo-profil;
                    
                    background-repeat: no-repeat;
                    background-color: $couleur__background-color__blanc;
                    border: 0.5vw solid $couleur__background-color__blanc;
                    border-radius: $taille__border-radius__photo-profil;
                    box-sizing: initial;
                }
            }

            .description
            {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: $typo__font-size__paragraphe;
                margin: 25px 50px;

                & > p
                {
                    margin: 10px 0;
                }
            }
        }


        @media screen and (min-width: $layout-mobile)
        {
            .contenu
            {
                .titre-section
                {
                    flex: 0;
                    margin-top: 5px;
                }

                &-grille
                {
                    flex-direction: row;

                    .contenant-image-profil
                    {
                        height: 100%;
                    }

                    .contenant-description
                    {
                        height: 100%;

                        .description
                        {
                            margin: 0;
                            padding: 25px;
                            height: 100%;
                            align-items: baseline;
                            
                            & > p
                            {
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
        
        @media screen and (min-width: $layout-mobile) and (max-width: $layout-desktop)
        {
            .contenu
            {
                &-grille
                {
                    display: grid;

                    .contenant-image-profil
                    {
                        display: grid;
                        grid-template-columns: 60% 40%;

                        .image-profil
                        {
                            justify-self: center;
                            align-self: center;
                            justify-content: center;
                        }

                        .vide
                        {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .contenant-description
                    {
                        display: grid;
                        grid-template-columns: 15% 85%;

                        .vide
                        {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        @media screen and (min-width: $layout-desktop)
        {
            .contenu
            {                
                &-grille
                {
                    margin: 0% 5%;
                    padding: 50px;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 25px;

                    .contenant-image-profil
                    {
                        grid-area: 1 / 1 / 2 / 2;
                    }

                    .contenant-description
                    {
                        grid-area: 1 / 2 / 2 / 5;
                    }
                }
            }
        }

        @media screen and (min-width: $layout-gigaScreen)
        {
            .contenu
            {
                &-grille
                {
                    display: flex;
                    width: 75%;

                    .contenant-image-profil
                    {
                        .image-profil
                        {
                            border: 0.5em solid $couleur__background-color__blanc;
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: $layout-gigaScreen)
    {
        min-height: 25vh;
    }

    .bouton-scroll
    {
        min-height: 175px;
        height: 200px;
        max-height: 250px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }
}