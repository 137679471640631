@import '../variables/layout.scss';

.Logo
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: $taille__width__logo;

    svg
    {
        width: 100%;
    }
}