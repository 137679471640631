@import '/src/code/style/variables/couleurs.scss';
@import '/src/code/style/variables/layout.scss';

*
{
    /* Mise à niveau du CSS */
    margin: 0;
    padding: 0;
    box-sizing: border-box;


    /* Barre de défilement custom */
    ::-webkit-scrollbar
    {
        height: $taille__height__barre-scroll;
        outline: $taille__outline__barre-scroll solid $couleur__background-color__blanc;
    }
    
    ::-webkit-scrollbar-thumb
    {
        background: linear-gradient(135deg, $couleur__background-color__secondaire, $couleur__background-color__tertiaire);
        border-radius: $taille__border-radius__barre-scroll-thumb;
    }
    
    ::-webkit-scrollbar-track
    {
        background-color: $couleur__background-color__blanc;
        border-radius: $taille__border-radius__barre-scroll-track;
    }
}