@import '../variables/couleurs.scss';
@import '../variables/layout.scss';
@import '../variables/polices.scss';

#ListeProjets
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;

    .titre-section
    {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-size: $typo__font-size__titre-section;
        width: min-content;
        border-bottom: 1px solid $couleur__background-color__tertiaire;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .contenant-texte-console
    {
        color: $couleur__font__tertiaire;
        font-size: 1rem;
        max-width: 100vw;
        text-align: center;
    }

    .Projets
    {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 25px 20px;
        margin: 1rem;
        padding: 1rem;
    }

    @media screen and (min-width: $layout-desktop)
    {
        max-width: calc($layout-desktop * 1.5);
    }

    @media screen and (min-width: $layout-gigaScreen)
    {
        max-width: clamp(calc($layout-desktop * 1.5), 30vw, calc($layout-gigaScreen * 1.5));
        min-height: 30vh;
    }
}