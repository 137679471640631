// Couleur de fond
$couleur__background-color__blanc       : #ffffff;
$couleur__background-color__primaire    : #efefef;
$couleur__background-color__secondaire  : #5271FF;
$couleur__background-color__tertiaire   : #7824D9;
$couleur__background-color__quaternaire : #4f24c5;
$couleur__background-color__quinaire    : #777777;
$couleur__background-color__sixte       : #202020;

// Fontes
$couleur__font__primaire    : #7824D9;
$couleur__font__secondaire  : #efefef;
$couleur__font__tertiaire   : #777777;
$couleur__font__quaternaire : #000000;