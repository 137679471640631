@import './variables/couleurs.scss';
@import './variables/layout.scss';
@import './variables/polices.scss';

#Entete
{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    @media screen and (min-width: $layout-desktop) and (max-width: $layout-gigaScreen)
    {
        min-height: 100vh;
    }

    .contenu
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 25px;
        flex: 1;

        h1
        {
            display: flex;
            flex-direction: column;
            margin: 10px;
            padding: 5px;
            text-align: center;
            color: $couleur__font__primaire;
            font-size: $typo__font-size__titre;
            font-family: $typo__font-family__secondaire;
            border-bottom: 2.5px solid $couleur__background-color__tertiaire;
            background: -webkit-linear-gradient(315deg, $couleur__background-color__secondaire, $couleur__background-color__tertiaire);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        h2
        {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin: 15px;
            font-size: 40px;
            color: $couleur__font__tertiaire;
            font-size: $typo__font-size__sous-titre;
        }

        @media screen and (min-width: $layout-mobile)
        {
            h1, h2
            {
                flex-direction: row;
            }

            h1 > p:last-child
            {
                text-indent: $typo__indent__titre;
            }

            h2 > p:last-child
            {
                text-indent: $typo__indent__sous-titre;
            }
        }
    }

    @media screen and (min-width: $layout-gigaScreen)
    {
        min-height: 25vh;
    }

    .transition
    {
        width: 100%;
        min-height: 175px;
        height: 200px;
        max-height: 250px;
        bottom: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}